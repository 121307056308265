import { Link } from "gatsby"
import styled from "styled-components"

export const Button = styled(Link)`
background: ${({primary}) => (primary ? "#F26A2E" : "rgba(142, 196, 136, 0.636)")};
white-space: nowrap;
padding-left: 10px;
padding-right: 10px;
text-align: center;
float:right;
color: white;
font-size: 16px;
font-size:x-large;
outline: none;
border: none;
min-width: 100px;
cursor: pointer;
text-decoration: none;
transition: 0.3s !important;
border-radius: ${({round}) => (round ? "50px" : "none")};
transform: translateY(-2px);

&:hover {
    background: ${({primary}) => (primary ? "rgba(142, 196, 136, 0.636)" : "#F26A2E")};
    transform: translateY(-2px);
}
}
`