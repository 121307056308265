import { createGlobalStyle } from "styled-components";
import "../styles/typography.css";

export const GlobalStyle = createGlobalStyle `
* {
    font-family: BrandonGrotesque_light;  
    margin: 0px;
    padding: 0;
    box-sizing: border-box; 
  
    letter-spacing: .2rem
}
`