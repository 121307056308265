import * as React from "react"
import AppHeader from "./AppHeader"
import Footer from "./Footer"
// ici on importe les styles css globaux
import { GlobalStyle } from "../assets/styles/GlobalStyles"
import { motion } from "framer-motion"
// npm install react-bootstrap bootstrap
import 'bootstrap/dist/css/bootstrap.css';

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <AppHeader />
      <motion.main
      initial={{
        opacity: 0.0,
        x: -200
      }}
      animate={{
        opacity: 1,
        x: 0
      }}
      exit={{
        opacity: 0.0,
        y: 200
      }}
      transition={{
        mass: 0.35,
        stiffness: 75,
        duration: 2.3,
      }}
    >
      {children}
      </motion.main>
      <Footer />
    </>
  )
}

export default Layout
 
