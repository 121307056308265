import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

const Footer = () => {
  return (

            <FooterLink>   Créé par 
                <Link href='mailto:regina.garcia31@gmail.com'>@Regina31</Link>
            </FooterLink>

  )
}

export default Footer

const FooterContainer = styled.div`
background-color: rgba(0, 0, 0, 0.725);
`

const FooterLinkWrapper = styled.div`

`
const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem 2rem;
    margin-right: 5rem;
    @media screen and (max-width: 768px) 
        align-items: center;
        padding: 0rem;
        padding-left: 1rem;
`

const FooterLink = styled.div`
background-color: rgba(0, 0, 0, 0.725);
color: white;
font-size: 1rem;
text-align: left;
margin-top: 1.5rem;
a {
    margin: 0.5rem;
    padding-left: 0rem;
    color: white;
    @media screen and (max-width: 768px) {
       
}
    &:hover {
        color: aqua;
        transition: 0.3s ease-out;  
        cursor: pointer;
    }
}
@media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
`

